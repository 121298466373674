import styled from 'styled-components';
import { DEVICE } from 'app/styles/variables/rwd';
import COLORS from 'app/styles/variables/colors';

export const SNonMobileDeviceWrapper = styled.div`
  display: none;

  @media ${DEVICE.IS_NOT_MOBILE} {
    position: fixed;
    inset: 0;
    display: flex;
    justify-content: center;
    background: ${COLORS.PRIMARY_BACKGROUND};
    z-index: 99999;
  }
`;
