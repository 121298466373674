import { createContext } from 'react';
import { IContainerContext } from 'core/types/container';

interface ILoginViewQueries {
  verifyUserByEmail: (email: string) => Promise<boolean>;
  loginUser: (email: string, pin: string) => void;
}

export const LoginViewContainerContext = createContext(
  {} as IContainerContext<ILoginViewQueries>,
);
