import styled from 'styled-components';
import { flexAlignJustifyCenter } from 'app/styles/helper.styles';
import { LAYOUTS } from 'app/styles/variables/layout';
import COLORS from 'app/styles/variables/colors';
import { FONT_SIZE, FONT_WEIGHT } from 'app/styles/variables/typography';

export const SHeaderWrapper = styled.header`
  position: absolute;
  inset: 0;
  ${flexAlignJustifyCenter};
  height: ${LAYOUTS.TOPBAR_HEIGHT};
  //background: ${COLORS.SECONDARY_BACKGROUND};
`;

export const SHeaderText = styled.h1`
  font-family: unset;
  font-size: ${FONT_SIZE.REGULAR};
  font-weight: ${FONT_WEIGHT.MEDIUM};
  color: ${COLORS.SECONDARY_TEXT};
`;
