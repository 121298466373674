import { ReactElement } from 'react';
import { TAvatarSize } from './types';
import { SAvatarImage } from './avatar.styles';

interface IProps {
  image?: string;
  placeholder?: string;
  size?: TAvatarSize;
}

const blankPixelPlaceholder =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=';

const Avatar = ({
  image,
  placeholder = blankPixelPlaceholder,
  size = 'regular',
}: IProps): ReactElement => {
  return <SAvatarImage src={image || placeholder} alt="" size={size} />;
};

export default Avatar;
