export const FONT_SIZE = {
  TINY: '1.2rem',
  EXTRA_SMALL: '1.3rem',
  SMALL: '1.4rem',
  REGULAR: '1.6rem',
  MEDIUM: '1.8rem',
  LARGE: '3rem',
  EXTRA_LARGE: '7rem',
};

export const FONT_WEIGHT = {
  THIN: 100,
  LIGHT: 300,
  REGULAR: 400,
  MEDIUM: 500,
  EXTRA_MEDIUM: 600,
  BOLD: 700,
  EXTRA_BOLD: 900,
};

export const FONT_SERIF = '"Nano Serif", serif';
