import { ReactElement, useContext, useMemo, useState } from 'react';
import { IScheduleMass } from 'app/models/schedule.model';
import ScheduleActivity from '../schedule-activity/schedule-activity';
import { SScheduleItemWrapper } from '../../schedule-list.styles';
import { IScheduleSelectedItem } from '../../types';
import { ScheduleListContext } from '../../schedule-list.context';
import { EScheduleListAction } from '../../schedule-list.reducer';
import { isEmpty } from 'lodash';
import ScheduleHeader from '../schedule-header/schedule-header';

interface IProps {
  date: string;
  name: string;
  masses: IScheduleMass[];
}

const ScheduleItem = ({ date, name, masses }: IProps): ReactElement => {
  const { dispatch } = useContext(ScheduleListContext);
  const [scheduleDate] = useState<Date>(new Date(date));

  const itemId = useMemo(
    () => scheduleDate.getTime().toString(),
    [scheduleDate],
  );

  const onItemClick = (item: IScheduleSelectedItem): void => {
    if (isEmpty(item)) {
      return;
    }

    dispatch({ type: EScheduleListAction.setSelectedItem, selectedItem: item });
  };

  return (
    <>
      <ins id={itemId} />
      <SScheduleItemWrapper>
        <ScheduleHeader text={name} date={scheduleDate} />
        {masses.map(({ hour, persons }, index, collection) => {
          const prevMass = collection[index - 1];
          return (
            <ScheduleActivity
              key={hour + index}
              hour={prevMass?.hour !== hour ? hour : undefined}
              persons={persons}
              onClick={(person) => onItemClick({ date, hour, person })}
            />
          );
        })}
      </SScheduleItemWrapper>
    </>
  );
};

export default ScheduleItem;
