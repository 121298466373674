import { ReactElement, useReducer } from 'react';
import { ISchedule } from 'app/models/schedule.model';
import ScheduleItem from './components/schedule-item/schedule-item';
import { SScheduleListWrapper } from './schedule-list.styles';
import ScheduleDropdownOptions from '../schedule-dropdown-options/schedule-dropdown-options';
import { ScheduleListContext } from './schedule-list.context';
import scheduleListReducer, { initialState } from './schedule-list.reducer';
import dropDownOptionsReducer, {
  EDropDownOptionsAction,
  initialState as dropDownInitialState,
} from 'core/components/dropdown-options/dropdown-options.reducer';
import { TActivityAction } from 'app/models/activity.model';
import { IScheduleSelectedItem } from './types';
import { isEmpty } from 'lodash';

interface IProps {
  schedule: ISchedule[];

  onItemDoAction: (
    item: IScheduleSelectedItem,
    action: TActivityAction,
  ) => void;
}

const ScheduleList = ({ schedule, onItemDoAction }: IProps): ReactElement => {
  const [state, dispatch] = useReducer(scheduleListReducer, initialState);
  const { selectedItem } = state;

  const [dropDownOptionsState, dropDownOptionsDispatch] = useReducer(
    dropDownOptionsReducer,
    dropDownInitialState,
  );

  const onItemAction = (action: TActivityAction) => {
    if (isEmpty(selectedItem)) {
      return;
    }

    if (!action) {
      return;
    }

    onItemDoAction(selectedItem, action);
    dropDownOptionsDispatch({ type: EDropDownOptionsAction.close });
  };

  return (
    <ScheduleListContext.Provider
      value={{
        state,
        dispatch,

        dropDownItemOptions: {
          state: dropDownOptionsState,
          dispatch: dropDownOptionsDispatch,
        },
      }}
    >
      <SScheduleListWrapper>
        {schedule.map((scheduleItem) => (
          <ScheduleItem key={scheduleItem.date} {...scheduleItem} />
        ))}
      </SScheduleListWrapper>
      <ScheduleDropdownOptions doAction={onItemAction} />
    </ScheduleListContext.Provider>
  );
};

export default ScheduleList;
