import { ReactElement } from 'react';
import { Button, TextField } from '@mui/material';
import { authView, utils } from 'app/constants/translations.json';
import { SFormField } from 'app/styles/form.styles';
import { useForm } from 'core/hooks/use-form';
import { ILoginForm, TLoginForm } from '../types';
import { SEmailFormButton } from './email-form.styles';

const { nextAction } = utils;
const {
  loginForm: { emailLabel },
} = authView;

interface IProps {
  onFormSubmit: (data: TLoginForm) => void;
}

const EmailForm = ({ onFormSubmit }: IProps): ReactElement => {
  const {
    data: { email },
    isValid,
    onSubmit,
    onChange,
  } = useForm<ILoginForm>();

  const onClick = () => {
    if (!email) {
      return;
    }

    onFormSubmit({ email });
  };

  return (
    <>
      <form onSubmit={onSubmit} onChange={onChange}>
        <SFormField>
          <TextField
            name="email"
            type="email"
            label={emailLabel}
            variant="outlined"
            required
          />
        </SFormField>
        <SEmailFormButton>
          <Button
            onClick={onClick}
            disabled={!isValid}
            variant="contained"
            type="submit"
            size="large"
          >
            {nextAction}
          </Button>
        </SEmailFormButton>
      </form>
    </>
  );
};

export default EmailForm;
