import { createContext, Dispatch } from 'react';
import { IAppState, TAppAction } from './app.reducer';

export interface IAppContext {
  state: IAppState;
  dispatch: Dispatch<TAppAction>;
}

const AppContext = createContext({} as IAppContext);

export default AppContext;
