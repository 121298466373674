import styled from 'styled-components';
import { flexAlignJustifyCenter } from 'app/styles/helper.styles';
import { LAYOUTS } from 'app/styles/variables/layout';
import COLORS from 'app/styles/variables/colors';
import { FONT_SIZE, FONT_WEIGHT } from 'app/styles/variables/typography';
import { APPEARANCE } from 'app/styles/variables/appearance';

export const STopBarWrapper = styled.header`
  position: fixed;
  //inset: 0 0 auto 0;
  top: 0;
  left: 0;
  right: 0;
  ${flexAlignJustifyCenter};
  justify-content: space-between;
  min-height: ${LAYOUTS.TOPBAR_HEIGHT};
  background: ${COLORS.PRIMARY_BACKGROUND};
  border-radius: 0 0 ${APPEARANCE.BORDER_RADIUS.LARGE}
    ${APPEARANCE.BORDER_RADIUS.LARGE};
  padding: ${APPEARANCE.MARGINS.SMALL};
  z-index: 1;
`;

export const STopBarText = styled.h1`
  font-size: ${FONT_SIZE.LARGE};
  font-weight: ${FONT_WEIGHT.MEDIUM};
`;

export const STopBarTool = styled.div`
  flex: 1;
  justify-self: flex-end;
`;
