import { FC, ReactElement, useContext, useEffect } from 'react';
import AppContext from 'app/components/app/app.context';
import { EAppAction } from 'app/components/app/app.reducer';
import { loginApi, verifyUserByEmailApi } from 'app/fetch/api/auth.api';
import { useNavigate } from 'react-router-dom';
import { authView } from 'app/constants/translations.json';
import { toast } from 'react-toastify';
import appConfig from 'app/config/config';
import navigation from 'app/config/navigation';
import { useIsUserAuthorized } from 'core/hooks/use-is-user-authorized';
import { LoginViewContainerContext } from './login-view.context';

const { loggingInMessage } = authView;

const LoginViewContainer: FC = ({ children }): ReactElement => {
  const navigate = useNavigate();
  const {
    dashboard: { path: dashboard },
  } = navigation;
  const isUserAuthorized = useIsUserAuthorized();

  const { dispatch: appDispatch } = useContext(AppContext);

  const {
    auth: { userTokenKey },
  } = appConfig;

  const loginUser = async (email: string, pin: string): Promise<void> => {
    toast(loggingInMessage);
    await loginApi(email, pin).then(({ userProfile, accessToken }) => {
      if (!accessToken) {
        return;
      }

      localStorage.setItem(userTokenKey, accessToken);
      appDispatch({ type: EAppAction.setLoggedUser, user: userProfile });
      navigate(dashboard);

      toast.dismiss();
    });
  };

  const verifyUserByEmail = async (email: string): Promise<boolean> => {
    appDispatch({ type: EAppAction.showLoader });

    return verifyUserByEmailApi(email).finally(() =>
      appDispatch({ type: EAppAction.hideLoader }),
    );
  };

  useEffect(() => {
    if (isUserAuthorized) {
      navigate(dashboard);

      return;
    }
  }, [isUserAuthorized]);

  return (
    <LoginViewContainerContext.Provider
      value={{
        queries: {
          verifyUserByEmail,
          loginUser,
        },
      }}
    >
      {children}
    </LoginViewContainerContext.Provider>
  );
};

export default LoginViewContainer;
