import { createContext } from 'react';
import { IContainerContext } from 'core/types/container';
import { IDashboard } from 'app/models/dashboard.model';

interface IDashboardViewQueries {
  getDashboard: () => Promise<IDashboard>;
}

export const DashboardViewContainerContext = createContext(
  {} as IContainerContext<IDashboardViewQueries>,
);
