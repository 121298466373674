import { FC, ReactElement } from 'react';
import styled from 'styled-components';
import { APPEARANCE } from 'app/styles/variables/appearance';

const SContainerWrapper = styled.div`
  padding-inline: ${APPEARANCE.MARGINS.REGULAR};
  margin-block: auto;
`;

const Container: FC = ({ children }): ReactElement => {
  return <SContainerWrapper>{children}</SContainerWrapper>;
};

export default Container;
