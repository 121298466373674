import React, { FC, ReactElement } from 'react';
import { IApplicationNavigation } from 'core/models/application-navigation';
import Icon from 'app/components/icon/Icon';
import {
  SMenuListItem,
  SMenuListItemLink,
  SMenuListItemContent,
  SMenuListItemText,
} from './menu-item.styles';

const MenuItem: FC<IApplicationNavigation> = ({
  text,
  icon,
  path,
}: IApplicationNavigation): ReactElement => {
  return (
    <SMenuListItem>
      <SMenuListItemLink to={path}>
        <SMenuListItemContent>
          {icon && <Icon name={icon} customSize="1.5em" />}
          <SMenuListItemText className="text">{text}</SMenuListItemText>
        </SMenuListItemContent>
      </SMenuListItemLink>
    </SMenuListItem>
  );
};

export default MenuItem;
