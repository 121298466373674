import styled from 'styled-components';
import { APPEARANCE } from 'app/styles/variables/appearance';
import { FONT_SIZE, FONT_WEIGHT } from 'app/styles/variables/typography';
import COLORS from 'app/styles/variables/colors';
import { smallText } from 'app/styles/helper.styles';

export const SScheduleHeader = styled.header`
  display: flex;
  align-items: baseline;
  gap: 25px;
  padding-block: 0 ${APPEARANCE.MARGINS.SMALL};
  padding-inline: ${APPEARANCE.MARGINS.REGULAR};
`;

export const SScheduleName = styled.h2`
  overflow: hidden;
  font-size: ${FONT_SIZE.SMALL};
  font-weight: ${FONT_WEIGHT.BOLD};
  color: ${COLORS.PRIMARY_ACTIVE};
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const SScheduleDate = styled.time`
  flex-basis: 50px;
  display: block;
  text-align: center;
  ${smallText};
  background-color: ${COLORS.PRIMARY_BACKGROUND};
  padding: 5px 6px;
  border-radius: 5px;
`;
