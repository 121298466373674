import { IAppCoreConfig } from 'core/models/app-config';
import { getEnvConfigValue } from 'core/helpers/get-env-config-value';
import { ToastContainerProps } from 'react-toastify/dist/types';

interface IAppConfig {
  defaultToastOptions: ToastContainerProps;
}

const appConfig: IAppConfig & IAppCoreConfig = {
  auth: {
    userTokenKey: 'userToken',
  },
  api: {
    baseUrl: getEnvConfigValue('API_BASE_URL'),
  },
  defaultToastOptions: {
    containerId: 'app-errors',
    limit: 1,
    theme: 'colored',
    icon: false,
    position: 'top-center',
    newestOnTop: true,
    enableMultiContainer: false,
  },
};

export default appConfig;
