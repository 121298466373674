import { css } from 'styled-components';
import { FONT_SIZE } from './variables/typography';
import COLORS from './variables/colors';

export const smallText = css`
  font-size: ${FONT_SIZE.TINY};
  color: ${COLORS.SECONDARY_TEXT};
`;

export const unStyledList = css`
  list-style: none;
  padding: 0;
  margin: 0;
`;

export const flexAlignJustifyCenter = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const softShadow = css`
  box-shadow: rgba(0, 0, 0, 0.1) 0 10px 50px;
`;

export const largeBottomShadow = css`
  box-shadow: rgba(50, 50, 93, 0.5) 0 30px 60px -12px,
    rgba(0, 0, 0, 0.3) 0 18px 36px -18px;
`;
