import styled from 'styled-components';
import { LAYOUTS } from 'app/styles/variables/layout';
import { DEVICE } from 'app/styles/variables/rwd';
import { flexAlignJustifyCenter } from 'app/styles/helper.styles';
import COLORS from 'app/styles/variables/colors';
import { FONT_SIZE, FONT_WEIGHT } from 'app/styles/variables/typography';
import { APPEARANCE } from 'app/styles/variables/appearance';

export const SDashboardViewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
`;

export const SDashboardViewTilesWrapper = styled.div`
  flex: 1;
  padding-inline: ${LAYOUTS.DASHBOARD_GAP} 0;
`;

export const SDashboardViewAppVersionWrapper = styled.footer`
  text-align: center;
  padding: ${APPEARANCE.MARGINS.SMALL};
`;

export const SDashboardTileCalendar = styled.div`
  flex: 1;
  display: flex;
  align-items: flex-start;
  align-self: flex-start;
  justify-content: center;
  line-height: 1;
  gap: 15px;
  margin-inline: 10px;

  @media ${DEVICE.MOBILE_LANDSCAPE} {
    align-self: center;
    margin-top: -50px;
  }

  span {
    ${flexAlignJustifyCenter};
    background: ${COLORS.PRIMARY_ACTIVE};
    border-radius: 8px;
    font-size: ${FONT_SIZE.REGULAR};
    //font-weight: ${FONT_WEIGHT.MEDIUM};
    color: ${COLORS.LIGHT_TEXT};
    text-align: center;
    text-transform: uppercase;
    padding: 5px 10px;
  }

  time {
    font-size: calc(${FONT_SIZE.MEDIUM} * 1.3);
    font-weight: ${FONT_WEIGHT.LIGHT};
    color: ${COLORS.PRIMARY_TEXT};
    text-transform: capitalize;
  }
`;
