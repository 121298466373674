import styled from 'styled-components';
import { LAYOUTS } from 'app/styles/variables/layout';

export const SMainLayoutWrapper = styled.div`
  display: grid;
  min-height: 100vh;
  grid-template-areas: 'topbar' 'header' 'content' 'footer';
  grid-template-rows: auto ${LAYOUTS.TOPBAR_HEIGHT} 1fr ${LAYOUTS.FOOTER_HEIGHT};
`;

export const SMainLayoutMenu = styled.div`
  position: fixed;
  inset: auto 0 0 0;
  z-index: 999;
`;

export const SMainLayoutView = styled.div<{ isLoaded: boolean }>`
  opacity: ${({ isLoaded }) => Number(!!isLoaded)};
  grid-area: content;
  transition: all 1s ease-out;
`;
