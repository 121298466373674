import { ReactElement, ReactNode } from 'react';
import { STopBarWrapper, STopBarText, STopBarTool } from './top-bar.styles';

interface IProps {
  text?: string;
  children?: ReactNode;
}

const TopBar = ({ text, children }: IProps): ReactElement => {
  return (
    <STopBarWrapper>
      {text && <STopBarText>{text}</STopBarText>}
      <STopBarTool>{children}</STopBarTool>
    </STopBarWrapper>
  );
};

export default TopBar;
