import styled from 'styled-components';
import COLORS from 'app/styles/variables/colors';

export const SAuthLayoutWrapper = styled.div`
  min-height: 100vh;
  background: ${COLORS.PRIMARY_BACKGROUND};
`;

export const SAuthLayoutView = styled.div<{ isLoading?: boolean }>`
  pointer-events: ${({ isLoading }) => (!!isLoading ? 'none' : 'initial')};
  transition: all 1s ease-out;
`;
