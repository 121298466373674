import { ReactElement, useEffect, useState } from 'react';
import Icon from '../icon/Icon';
import Ripples from 'react-ripples';
import { SButtonText, SButtonWrapper } from './button.styles';
import { TIcon } from '../icon/types';

type TButton = 'add' | 'edit' | 'remove';

interface IProps {
  text?: string;
  icon?: TIcon;
  type?: TButton;
  onClick?: () => void;
}

const Button = ({ text, type, icon, onClick }: IProps): ReactElement => {
  const [buttonIcon, setButtonIcon] = useState<TIcon>();

  useEffect(() => {
    if (icon) {
      setButtonIcon(icon);

      return;
    }
    setButtonIcon(type);
  }, [icon, type]);

  return (
    <Ripples>
      <SButtonWrapper onClick={onClick}>
        {buttonIcon && <Icon name={buttonIcon} size="2x" />}
        {text && <SButtonText>{text}</SButtonText>}
      </SButtonWrapper>
    </Ripples>
  );
};

export default Button;
