import styled from 'styled-components';
import { LAYOUTS } from 'app/styles/variables/layout';
import { largeBottomShadow } from 'app/styles/helper.styles';
import { APPEARANCE } from 'app/styles/variables/appearance';

export const SAddressBookViewWrapper = styled.div``;

export const SButtonWrapper = styled.div`
  position: fixed;
  right: 0;
  overflow: hidden;
  bottom: ${LAYOUTS.FOOTER_HEIGHT};
  border-radius: ${APPEARANCE.MARGINS.SMALL};
  margin: ${APPEARANCE.MARGINS.REGULAR};
  ${largeBottomShadow};
`;
