import styled from 'styled-components';
import { LAYOUTS } from 'app/styles/variables/layout';
import COLORS from 'app/styles/variables/colors';
import { APPEARANCE } from 'app/styles/variables/appearance';

export const SMenuNav = styled.nav`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${LAYOUTS.FOOTER_HEIGHT};
  background-color: ${COLORS.PRIMARY_BACKGROUND};
  border-radius: ${APPEARANCE.BORDER_RADIUS.LARGE}
    ${APPEARANCE.BORDER_RADIUS.LARGE} 0 0;
`;

export const SMenuList = styled.ul`
  list-style: none;
  display: flex;
  justify-content: space-evenly;
  padding: 0;
  margin: 0;
`;
