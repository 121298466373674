import { ReactElement, ReactNode } from 'react';
import { SDashboardGridWrapper } from './dashboard-grid.styles';

interface IProps {
  children: ReactNode;
}

const DashboardGrid = ({ children }: IProps): ReactElement => {
  return <SDashboardGridWrapper>{children}</SDashboardGridWrapper>;
};

export default DashboardGrid;
