import { ReactElement, useEffect, useState } from 'react';
import { TLoginForm } from '../types';
import { StatefulPinInput } from 'react-input-pin-code';
import COLORS from 'app/styles/variables/colors';
import { Link } from '@mui/material';
import { SPinForm, SPinField, SPinResendLink } from './pin-form.styles';
import { utils, authView } from 'app/constants/translations.json';

const { resendAction } = utils;
const { pinNotArriveText } = authView;

interface IProps {
  onResend: () => void;
  onFormSubmit: (data: TLoginForm) => void;
}

const PinForm = ({ onFormSubmit, onResend }: IProps): ReactElement => {
  const [pin, setPin] = useState('');

  const onPinComplete = (values: string[]) => {
    const pinCode = values.join('');
    if (!pinCode) {
      return;
    }

    setPin(pinCode);
  };

  useEffect(() => {
    if (!pin) {
      return;
    }

    onFormSubmit({ pin });
  }, [pin]);

  return (
    <SPinForm>
      <SPinField>
        <StatefulPinInput
          length={3}
          onComplete={onPinComplete}
          size="lg"
          placeholder=""
          showState={false}
          focusBorderColor={COLORS.PRIMARY_ACTIVE}
        />
      </SPinField>
      <SPinResendLink>
        <small>{pinNotArriveText}</small>
        <br />
        <Link component="button" onClick={onResend}>
          {resendAction}
        </Link>
      </SPinResendLink>
    </SPinForm>
  );
};

export default PinForm;
