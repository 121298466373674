import { memo } from 'react';
import { FormattedDate } from 'react-intl';
import {
  SScheduleDate,
  SScheduleHeader,
  SScheduleName,
} from './schedule-header.styles';

interface IProps {
  text: string;
  date: Date;
}

const ScheduleHeader = ({ text, date }: IProps) => {
  return (
    <>
      <SScheduleHeader>
        <SScheduleDate>
          <strong>
            <FormattedDate value={date} day="2-digit" />
            {'.'}
            <FormattedDate value={date} month="2-digit" />
          </strong>
        </SScheduleDate>
        <SScheduleName>{text}</SScheduleName>
      </SScheduleHeader>
    </>
  );
};

export default memo(ScheduleHeader);
