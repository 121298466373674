import { createTheme } from '@mui/material';
import COLORS from './variables/colors';

export const themeMUI = createTheme({
  typography: {
    fontSize: 24,
  },
  palette: {
    primary: {
      main: COLORS.PALETTE.PRIMARY_GOLD,
    },
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          width: '100%',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
        contained: {
          color: COLORS.LIGHT_TEXT,
        },
      },
      variants: [
        {
          props: { variant: 'dropdown-option' },
          style: {
            color: COLORS.PRIMARY_ACTIVE,
            fontWeight: 600,
            textTransform: 'none',
            padding: 15,
            ':hover': {
              backgroundColor: 'transparent',
            },
          },
        },
      ],
    },
  },
});

declare module '@mui/material/button' {
  interface ButtonPropsVariantOverrides {
    'dropdown-option': true;
  }
}
