import { ReactElement } from 'react';
import { SHeaderWrapper, SHeaderText } from './header.styles';

interface IProps {
  text: string;
}

const Header = ({ text }: IProps): ReactElement => {
  return (
    <SHeaderWrapper>
      <SHeaderText>{text}</SHeaderText>
    </SHeaderWrapper>
  );
};

export default Header;
