import { ReactElement } from 'react';
import {
  nonMobileDeviceText,
  dearBrotherPhrase,
} from 'app/constants/translations.json';
import InfoPage from '../info-page/info-page';
import { SNonMobileDeviceWrapper } from './non-mobile-device-splash.styles';

const NonMobileDeviceSplash = (): ReactElement => {
  return (
    <SNonMobileDeviceWrapper>
      <InfoPage header={`${dearBrotherPhrase}!`} text={nonMobileDeviceText} />
    </SNonMobileDeviceWrapper>
  );
};

export default NonMobileDeviceSplash;
