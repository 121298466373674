import styled from 'styled-components';
import COLORS from 'app/styles/variables/colors';
import { APPEARANCE } from 'app/styles/variables/appearance';
import { FONT_SERIF, FONT_SIZE } from 'app/styles/variables/typography';

export const SLoginView = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-inline: ${APPEARANCE.MARGINS.REGULAR};

  h1 {
    color: ${COLORS.PRIMARY_ACTIVE};
  }
`;

export const SLoginMessageWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: flex-end;
`;

export const SLoginMessage = styled.div`
  max-width: 280px;
  min-height: 200px;
  font-family: ${FONT_SERIF};
  font-size: ${FONT_SIZE.MEDIUM};
  color: ${COLORS.PRIMARY_TEXT}EE;
`;

export const SLoginFormWrapper = styled.div`
  flex: 1;
`;
