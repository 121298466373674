import styled from 'styled-components';
import { flexAlignJustifyCenter } from 'app/styles/helper.styles';
import COLORS from 'app/styles/variables/colors';
import { APPEARANCE } from 'app/styles/variables/appearance';

export const SButtonWrapper = styled.button`
  ${flexAlignJustifyCenter};
  min-width: 68px;
  min-height: 68px;
  border: none;
  overflow: hidden;
  border-spacing: 0;
  background-color: ${COLORS.PRIMARY_ACTIVE};
  color: ${COLORS.LIGHT_TEXT};
  border-radius: ${APPEARANCE.BORDER_RADIUS.REGULAR};
`;

export const SButtonText = styled.span``;
