import { ReactElement, useCallback, useEffect, useState } from 'react';
import COLORS from 'app/styles/variables/colors';
import { useNavigate } from 'react-router-dom';
import { parseLetterToNbsp } from 'core/helpers/parse-letter-to-nbsp';
import {
  SDashboardTileRipple,
  SDashboardTileWrapper,
  SDashboardTileCaption,
  SDashboardTileContent,
  SDashboardTileValue,
} from './dashboard-tile.styles';
import { TIcon } from 'app/components/icon/types';

interface IProps {
  children?: ReactElement;
  caption?: string;
  value?: string | number;
  icon?: TIcon;
  onClick?: () => void;
  cols?: string;
  rows?: string;
  link?: string;
}

const DashboardTile = ({
  children,
  caption,
  value,
  // icon,
  onClick,
  link,
  cols,
  rows,
}: IProps): ReactElement => {
  const navigate = useNavigate();
  const [formattedCaption, setFormattedCaption] = useState<string>('');

  useEffect(() => {
    if (!caption) {
      return;
    }

    setFormattedCaption(parseLetterToNbsp(caption));
  }, [caption]);

  const handleClick = useCallback(() => {
    if (!onClick && !link) {
      return;
    }

    if (!!onClick) {
      onClick();
    }

    if (!link) {
      return;
    }

    const timer = setTimeout(() => navigate(link), 500);
    return () => clearTimeout(timer);
  }, [link, onClick]);

  return (
    <SDashboardTileRipple
      onClick={handleClick}
      during={1500}
      color={COLORS.LIGHT_ACTIVE}
      cols={cols}
      rows={rows}
    >
      <SDashboardTileWrapper>
        {formattedCaption && (
          <>
            <SDashboardTileCaption>{formattedCaption}</SDashboardTileCaption>
            {/*{icon && (*/}
            {/*  <DashboardTileIcon>*/}
            {/*    <icon name={icon} size="10x" />*/}
            {/*  </DashboardTileIcon>*/}
            {/*)}*/}
          </>
        )}
        <SDashboardTileContent>
          {value && <SDashboardTileValue>{value}</SDashboardTileValue>}
          {children}
        </SDashboardTileContent>
      </SDashboardTileWrapper>
    </SDashboardTileRipple>
  );
};

export default DashboardTile;
