import { useEffect } from 'react';

const useChangeMetaThemeColor = (color: string): void => {
  useEffect(() => {
    if (!color) {
      return;
    }

    const theme = document.querySelector('meta[name="theme-color"]');
    const colorTheme = theme?.getAttribute('content');
    if (!colorTheme) {
      return;
    }

    theme?.setAttribute('content', color);
    return () => {
      if (!colorTheme) {
        return;
      }

      theme?.setAttribute('content', colorTheme);
    };
  }, [color]);
};

export default useChangeMetaThemeColor;
