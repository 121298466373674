import { FC, ReactElement, useContext } from 'react';
import AppContext from 'app/components/app/app.context';
import { DashboardViewContainerContext } from './dashboard-view.context';
import { EAppAction } from 'app/components/app/app.reducer';
import { getDashboardApi } from 'app/fetch/api/dashboard.api';

const DashboardViewContainer: FC = ({ children }): ReactElement => {
  const { dispatch: appDispatch } = useContext(AppContext);

  const getDashboard = async () => {
    appDispatch({ type: EAppAction.showLoader });

    return getDashboardApi().finally(() =>
      appDispatch({ type: EAppAction.hideLoader }),
    );
  };

  return (
    <>
      <DashboardViewContainerContext.Provider
        value={{
          queries: {
            getDashboard,
          },
        }}
      >
        {children}
      </DashboardViewContainerContext.Provider>
    </>
  );
};

export default DashboardViewContainer;
