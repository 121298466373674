import { ReactElement } from 'react';
import { IAddress } from 'app/models/address.model';
import Icon from 'app/components/icon/Icon';
import {
  SAddressBookListItemWrapper,
  SAddressBookListItemIconPin,
  SAddressBookListItemContent,
  SAddressBookListItemDescription,
  SAddressBookListItemName,
} from './address-book-list-item.styles';

interface IProps {
  address: IAddress;
  canDrag?: boolean;
}

const AddressBookListItem = ({
  address,
  canDrag = false,
}: IProps): ReactElement => {
  return (
    <>
      <SAddressBookListItemWrapper>
        <SAddressBookListItemIconPin
          data-movable-handle={!canDrag && undefined}
        >
          {!canDrag && <span data-movable-handle />}
          <Icon name={!canDrag ? 'addressBook' : 'move'} customSize="1.4em" />
        </SAddressBookListItemIconPin>
        <SAddressBookListItemContent>
          <SAddressBookListItemName>
            {address.address1}
            <small>{address.address2}</small>
          </SAddressBookListItemName>
          <SAddressBookListItemDescription>
            <ul>
              <li>
                <span>p. {address.name}</span>
              </li>
              <li>
                <Icon name="communion" customSize="1em" />
                <span>{address.counter}</span>
              </li>
            </ul>
          </SAddressBookListItemDescription>
        </SAddressBookListItemContent>
      </SAddressBookListItemWrapper>
    </>
  );
};

export default AddressBookListItem;
