import React, { ReactElement } from 'react';
import { default as RemixIcon } from 'react-remixicon';
import { SIconWrapper } from './icon.styles';
import icons, { TIconSize, TIcon } from './types';

interface IProps {
  name: TIcon;
  size?: TIconSize;
  customSize?: string;
}

const Icon = ({ name, size = '1x', customSize }: IProps): ReactElement => {
  return (
    <SIconWrapper customSize={customSize}>
      <RemixIcon name={icons[name]} type="line" size={size} />
    </SIconWrapper>
  );
};

export default Icon;
