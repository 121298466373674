import { ReactElement } from 'react';
import { SSpinnerWrapper, SSpinner } from './app-loader.styles';

const AppLoader = (): ReactElement => {
  return (
    <>
      <SSpinnerWrapper>
        <SSpinner />
      </SSpinnerWrapper>
    </>
  );
};

export default AppLoader;
