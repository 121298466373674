import React, { ReactElement } from 'react';
import MenuItem from './components/menu-item/menu-item';
import mainMenu from 'app/config/navigation';
import { SMenuNav, SMenuList } from './menu.styles';

const Menu = (): ReactElement => {
  return (
    <SMenuNav>
      <SMenuList>
        {Object.entries(mainMenu).map(([key, navigation]) => (
          <MenuItem key={key} {...navigation} />
        ))}
      </SMenuList>
    </SMenuNav>
  );
};

export default Menu;
