import {
  IScheduleListState,
  TScheduleListAction,
} from './schedule-list.reducer';
import { createContext, Dispatch } from 'react';
import {
  IDropDownOptionsState,
  TDropDownOptionsAction,
} from 'core/components/dropdown-options/dropdown-options.reducer';

interface IScheduleListContext {
  state: IScheduleListState;
  dispatch: Dispatch<TScheduleListAction>;

  dropDownItemOptions: {
    state: IDropDownOptionsState;
    dispatch: Dispatch<TDropDownOptionsAction>;
  };
}

export const ScheduleListContext = createContext({} as IScheduleListContext);
