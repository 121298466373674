import { memo, ReactElement, useContext, useEffect, useState } from 'react';
import Container from '../../components/container/container';
import { getAddressesApi } from '../../fetch/api/addresses.api';
import { IAddress } from '../../models/address.model';
import AddressBookList from './components/address-book-list/address-book-list';
import { addressBookView } from '../../constants/translations.json';
import Header from '../../components/header/header';
import Button from '../../components/button/button';
import AppContext from '../../components/app/app.context';
import { EAppAction } from '../../components/app/app.reducer';
import {
  SAddressBookViewWrapper,
  SButtonWrapper,
} from './address-book-view.styles';

const AddressBookView = (): ReactElement => {
  const { dispatch: appDispatch } = useContext(AppContext);
  const [addresses, setAddresses] = useState<IAddress[]>([]);
  const { headerText } = addressBookView;

  useEffect(() => {
    const fetchAddresses = async () => {
      appDispatch({
        type: EAppAction.showLoader,
      });

      const addressesOfParishioners = await getAddressesApi();

      setAddresses(addressesOfParishioners);
      appDispatch({
        type: EAppAction.hideLoader,
      });
    };

    fetchAddresses();
  }, []);

  const onMoveAddresses = (movedAddresses: IAddress[]) => {
    setAddresses(movedAddresses);
  };

  return (
    <>
      <Header text={headerText} />
      <SAddressBookViewWrapper>
        <AddressBookList
          addresses={addresses}
          onMoveAddresses={onMoveAddresses}
        />
        {!addresses && (
          <Container>
            <SButtonWrapper>
              <Button type="add" />
            </SButtonWrapper>
          </Container>
        )}
      </SAddressBookViewWrapper>
    </>
  );
};

export default memo(AddressBookView);
