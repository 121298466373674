import React, { ReactElement } from 'react';
import { Fade } from '@mui/material';
import { SDropDownOptions, SDropDownPopper } from './dropdown-options.styles';

interface IProps {
  isOpen: boolean;
  forElement?: HTMLElement;
  children: ReactElement;
}

const DropDownOptions = ({
  isOpen,
  forElement,
  children,
}: IProps): ReactElement => {
  return (
    <>
      <SDropDownPopper open={isOpen} anchorEl={forElement} transition>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <SDropDownOptions>
              <>
                <div className="arrow"></div>
                {children}
              </>
            </SDropDownOptions>
          </Fade>
        )}
      </SDropDownPopper>
    </>
  );
};

export default DropDownOptions;
