import { FC, ReactElement } from 'react';
import { version } from '../../../../package.json';
import { dashboardView } from 'app/constants/translations.json';
import styled from 'styled-components';
import { FONT_WEIGHT } from 'app/styles/variables/typography';

const SAppVersionWrapper = styled.div`
  font-weight: ${FONT_WEIGHT.MEDIUM};
`;

const AppVersion: FC = (): ReactElement => {
  const { versionText } = dashboardView;

  return (
    <SAppVersionWrapper>
      <small>
        {versionText} {version}
      </small>
    </SAppVersionWrapper>
  );
};

export default AppVersion;
