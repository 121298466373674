import styled, { keyframes } from 'styled-components';
import { NavLink } from 'react-router-dom';
import COLORS from 'app/styles/variables/colors';
import { FONT_SIZE, FONT_WEIGHT } from 'app/styles/variables/typography';
import { APPEARANCE } from 'app/styles/variables/appearance';

export const moveText = keyframes`
  from {
    opacity: 0;
    margin-left: -25px;
  }
  to {
    opacity: 1;
  }
`;

export const SMenuListItem = styled.li``;

export const SMenuListItemLink = styled(NavLink)`
  display: block;
  border-radius: ${APPEARANCE.BORDER_RADIUS.MEDIUM};
  padding: 12px 25px;

  &.active {
    background-color: ${COLORS.PALETTE.LIGHT_GOLD};
    color: ${COLORS.PRIMARY_ACTIVE};

    .text {
      font-size: ${FONT_SIZE.EXTRA_SMALL};
      font-weight: ${FONT_WEIGHT.MEDIUM};
      animation: ${moveText} 0.2s ease-in-out forwards;
    }
  }
`;

export const SMenuListItemContent = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const SMenuListItemText = styled.p`
  font-size: 0;
  margin: 0;
`;
