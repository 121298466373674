import React, { useEffect, useReducer } from 'react';
import GlobalStyles from 'app/styles/global.styles';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import applicationRoutes from 'app/config/routes';
import NonMobileDeviceSplash from '../non-mobile-device-splash/non-mobile-device-splash';
import PrivateRoute from 'core/components/private-route/private-route';
import { IntlProvider } from 'react-intl';
import { ToastContainer } from 'react-toastify';
import appConfig from 'app/config/config';
import AppLoader from 'app/components/app/components/app-loader/app-loader';
import LoginView from 'app/views/login-view/login-view';
import appLoaderReducer, { EAppAction, initialState } from './app.reducer';
import AppContext from './app.context';
import { ThemeProvider } from '@mui/material';
import { themeMUI } from 'app/styles/theme';
import LoginViewContainer from 'app/views/login-view/login-view.container';
import AuthLayout from 'app/layouts/auth-layout/auth-layout';
import { getMeApi } from 'app/fetch/api/me.api';
import useIsUserLoggedIn from 'core/hooks/use-is-user-logged-in';

const { defaultToastOptions } = appConfig;

const HIDE_LOADER_AFTER_TIME = 10000;

function App() {
  const isLoggedIn = useIsUserLoggedIn();
  const [state, dispatch] = useReducer(appLoaderReducer, initialState);
  const { isLoading } = state;

  useEffect(() => {
    if (!isLoggedIn) {
      return;
    }

    getMeApi().then((user) =>
      dispatch({ type: EAppAction.setLoggedUser, user }),
    );
  }, [isLoggedIn]);

  useEffect(() => {
    if (!isLoading) {
      return;
    }

    const timer = setTimeout(() => {
      dispatch({ type: EAppAction.hideLoader });
    }, HIDE_LOADER_AFTER_TIME);
    return () => {
      clearTimeout(timer);
    };
  }, [isLoading]);

  return (
    <>
      <IntlProvider locale="pl" defaultLocale="en">
        <AppContext.Provider value={{ state, dispatch }}>
          <ThemeProvider theme={themeMUI}>
            {isLoading && <AppLoader />}
            <GlobalStyles />
            <NonMobileDeviceSplash />
            <ToastContainer {...defaultToastOptions} />
            <Router>
              <Routes>
                <Route element={<AuthLayout />}>
                  <Route
                    path="/login"
                    element={
                      <LoginViewContainer>
                        <LoginView />
                      </LoginViewContainer>
                    }
                  />
                </Route>
                {applicationRoutes.map(
                  ({
                    path,
                    container: Container,
                    view: View,
                    layout: Layout,
                  }) => (
                    <>
                      <Route element={<Layout />}>
                        <Route
                          path={path}
                          element={
                            <PrivateRoute>
                              {!!Container ? (
                                <Container>
                                  <View />
                                </Container>
                              ) : (
                                <View />
                              )}
                            </PrivateRoute>
                          }
                        />
                      </Route>
                    </>
                  ),
                )}
              </Routes>
            </Router>
          </ThemeProvider>
        </AppContext.Provider>
      </IntlProvider>
    </>
  );
}

export default App;
