import { arrayMove, List } from 'react-movable';
import { IAddress } from 'app/models/address.model';
import AddressBookListItem from './components/address-book-list-item';
import { ReactElement } from 'react';
import {
  SAddressBookListItemWrapper,
  SAddressBookListWrapper,
} from './address-book-list.styles';

interface IProps {
  addresses: IAddress[];
  onMoveAddresses: (addresses: IAddress[]) => void;
}

const AddressBookList = ({
  addresses,
  onMoveAddresses,
}: IProps): ReactElement => {
  // TODO: After move items in console is message about memory of leak (react-movable library is buggy!!)
  return (
    <List
      lockVertically
      values={addresses}
      onChange={({ oldIndex, newIndex }) =>
        onMoveAddresses(arrayMove<IAddress>(addresses, oldIndex, newIndex))
      }
      renderList={({ children, props }) => (
        <SAddressBookListWrapper {...props}>{children}</SAddressBookListWrapper>
      )}
      renderItem={({ value, props }) => (
        <SAddressBookListItemWrapper {...props}>
          <AddressBookListItem address={value} />
        </SAddressBookListItemWrapper>
      )}
    />
  );
};

export default AddressBookList;
