import styled from 'styled-components';
import COLORS from 'app/styles/variables/colors';
import { FONT_SERIF, FONT_SIZE } from 'app/styles/variables/typography';

export const SInfoPageWrapper = styled.div`
  display: flex;
  min-height: 100vh;
`;

export const SInfoPageMain = styled.main`
  max-width: 320px;

  h1 {
    color: ${COLORS.PRIMARY_ACTIVE};
  }

  p {
    font-size: ${FONT_SIZE.MEDIUM};
    font-family: ${FONT_SERIF};
    color: ${COLORS.PRIMARY_TEXT}EE;
    line-height: 1.5;
  }
`;
