export enum EAvatarSize {
  small = 40,
  regular = 60,
  medium = 68,
  xmedium = 80,
  large = 100,
  xlarge = 120,
}

export type TAvatarSize = keyof typeof EAvatarSize;
