import { flexAlignJustifyCenter, unStyledList } from 'app/styles/helper.styles';
import styled from 'styled-components';
import COLORS from 'app/styles/variables/colors';
import { FONT_SIZE, FONT_WEIGHT } from 'app/styles/variables/typography';
import { APPEARANCE } from 'app/styles/variables/appearance';

export const SAddressBookListItemWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  padding: ${APPEARANCE.MARGINS.SMALL} ${APPEARANCE.MARGINS.EXTRA_SMALL};
`;

export const SAddressBookListItemIconPin = styled.div`
  ${flexAlignJustifyCenter};
  width: 62px;
  height: 62px;
  //background: ${COLORS.PRIMARY_BACKGROUND};
  border-radius: calc(${APPEARANCE.BORDER_RADIUS.REGULAR} * 2);
  color: ${COLORS.PRIMARY_ACTIVE};
`;

export const SAddressBookListItemContent = styled.div`
  flex: 1;
  display: flex;
  align-self: stretch;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
`;

export const SAddressBookListItemName = styled.h3`
  display: flex;
  align-items: center;
  gap: 15px;

  small {
    font-size: ${FONT_SIZE.SMALL};
    font-weight: ${FONT_WEIGHT.MEDIUM};
  }
`;

export const SAddressBookListItemDescription = styled.div`
  font-size: ${FONT_SIZE.SMALL};
  font-weight: ${FONT_WEIGHT.MEDIUM};
  color: ${COLORS.PRIMARY_ACTIVE};

  ul {
    ${unStyledList};
    display: flex;
    align-items: center;
    gap: 15px;

    li {
      ${flexAlignJustifyCenter};
      gap: 2px;
    }
  }
`;
