import styled from 'styled-components';
import { FONT_SIZE } from 'app/styles/variables/typography';
import { APPEARANCE } from 'app/styles/variables/appearance';
import { flexAlignJustifyCenter } from 'app/styles/helper.styles';

export const SPinForm = styled.div`
  ${flexAlignJustifyCenter};
  flex-direction: column;
`;

export const SPinField = styled.div`
  input {
    width: 60px;
    height: 90px;
    font-family: inherit;
    font-size: ${FONT_SIZE.LARGE};
    color: inherit;
  }
`;

export const SPinResendLink = styled.div`
  text-align: center;
  margin-block: ${APPEARANCE.MARGINS.LARGE};
`;
