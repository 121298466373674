import styled from 'styled-components';
import { APPEARANCE } from 'app/styles/variables/appearance';
import COLORS from 'app/styles/variables/colors';
import { Popper } from '@mui/material';

const ARROW_SIZE = '20px';

export const SDropDownPopper = styled(Popper)({
  '.arrow': {
    position: 'absolute',
    top: `-${ARROW_SIZE}`,
    left: '50%',
    width: 0,
    height: 0,
    borderLeft: `${ARROW_SIZE} solid transparent`,
    borderRight: `${ARROW_SIZE} solid transparent`,
    borderBottom: `${ARROW_SIZE} solid ${COLORS.PRIMARY_BACKGROUND}`,
    transform: 'translate(-50%, 0)',
  },
  '&[data-popper-placement*="top"] .arrow': {
    top: 'auto',
    bottom: `-${ARROW_SIZE}`,
    borderTop: `${ARROW_SIZE} solid ${COLORS.PRIMARY_BACKGROUND}`,
    borderBottom: 'none',
  },
  hr: {
    width: '100%',
    border: 0,
    height: '1px',
    backgroundColor: COLORS.SECONDARY_BACKGROUND,
    margin: 0,
    padding: 0,
  },
});

export const SDropDownOptions = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 80vw;
  min-width: 320px;
  background: ${COLORS.PRIMARY_BACKGROUND};
  border-radius: ${APPEARANCE.BORDER_RADIUS.REGULAR};
  box-shadow: ${APPEARANCE.SHADOWS.DEFAULT};
`;
