export interface IDropDownOptionsState {
  isOpen: boolean;
  forElement?: HTMLElement;
}

export const initialState: IDropDownOptionsState = {
  isOpen: false,
};

export enum EDropDownOptionsAction {
  open,
  close,
  forElement,
}

export type TDropDownOptionsAction =
  | {
      type: EDropDownOptionsAction.open;
    }
  | {
      type: EDropDownOptionsAction.close;
    }
  | {
      type: EDropDownOptionsAction.forElement;
      forElement: HTMLElement;
    };

const dropDownOptionsReducer = (
  state: IDropDownOptionsState,
  action: TDropDownOptionsAction,
): IDropDownOptionsState => {
  switch (action.type) {
    case EDropDownOptionsAction.open: {
      return {
        ...state,
        isOpen: true,
      };
    }
    case EDropDownOptionsAction.close: {
      return {
        ...state,
        isOpen: false,
      };
    }
    case EDropDownOptionsAction.forElement: {
      const { forElement } = action;
      return {
        ...state,
        forElement,
      };
    }
    default:
      return { ...state };
  }
};

export default dropDownOptionsReducer;
