import {
  IApiEndpoint,
  TApiEndpointParams,
  TApiEndpoints,
} from 'core/models/api-endpoint';

interface IApiEndpoints {
  getSchedule: IApiEndpoint;
  getAddresses: IApiEndpoint;
  getDashboard: IApiEndpoint;
  getLogin: IApiEndpoint;

  postVerifyEmail: IApiEndpoint;
  postLogin: IApiEndpoint;

  getMe: IApiEndpoint;

  postActivityAdd: IApiEndpoint;
}

const apiEndpoints: TApiEndpoints<IApiEndpoints> = {
  getSchedule: { path: '/schedule' },
  getAddresses: { path: '/addresses' },
  getDashboard: { path: '/dashboard' },
  getLogin: { path: '/auth/login/:token' },

  postVerifyEmail: { path: '/auth/verifyEmail' },
  postLogin: { path: '/auth/login' },

  getMe: { path: '/me' },

  postActivityAdd: { path: '/activity' },
};

const prepareEndpointWithParams = (
  endpoint: IApiEndpoint,
  uriParams?: TApiEndpointParams,
): IApiEndpoint => {
  if (!uriParams) {
    return endpoint;
  }

  Object.entries(uriParams).map(([key, value]) => {
    endpoint.path = endpoint.path.replace(`:${key}`, String(value));
  });

  return endpoint;
};

export const getEndpoint = (
  endpointName: keyof IApiEndpoints,
  uriParams?: TApiEndpointParams,
): IApiEndpoint => {
  const endpoint = apiEndpoints[endpointName];
  if (!endpoint) {
    throw new Error(`API endpoint ${endpoint} not exists!`);
  }

  return prepareEndpointWithParams(endpoint, uriParams);
};

export default apiEndpoints;
