const BREAKPOINT = {
  SMALL: '320px',
  MEDIUM: '420px',
  EXTRA_MEDIUM: '768px',
  LARGE: '992px',
  EXTRA_LARGE: '1200px',
  HUGE: '1400px',
};

export const DEVICE = {
  IS_MOBILE: `(min-width: ${BREAKPOINT.SMALL} and orientation: portrait)`,
  IS_NOT_MOBILE: `(min-width: ${BREAKPOINT.EXTRA_MEDIUM}), (orientation: landscape)`,

  MOBILE: `(min-width: ${BREAKPOINT.SMALL})`,
  MOBILE_LANDSCAPE: `(min-width: ${BREAKPOINT.MEDIUM})`,
  TABLET: `(min-width: ${BREAKPOINT.EXTRA_MEDIUM})`,
  TABLET_LANDSCAPE: `(min-width: ${BREAKPOINT.LARGE})`,
  LAPTOP: `(min-width: ${BREAKPOINT.EXTRA_LARGE})`,
  DESKTOP: `(min-width: ${BREAKPOINT.HUGE})`,
};
