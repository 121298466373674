import styled, { css } from 'styled-components';
import COLORS from 'app/styles/variables/colors';
import { TAvatarSize, EAvatarSize } from './types';
import { APPEARANCE } from 'app/styles/variables/appearance';

export const SAvatarImage = styled.img<{ size?: TAvatarSize }>`
  width: 80px;
  height: 80px;
  border-radius: ${APPEARANCE.BORDER_RADIUS.LARGE};

  ${({ size }) =>
    !!size &&
    css`
      width: ${EAvatarSize[size]}px;
      height: ${EAvatarSize[size]}px;
      border-radius: ${EAvatarSize[size] / 2.5}px;
    `}

  background: ${COLORS.PALETTE.LIGHT_GOLD};
  object-fit: cover;
  object-position: center;
  filter: grayscale(1) contrast(1.2) sepia(0.35);
`;
