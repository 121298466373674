import styled, { css } from 'styled-components';
import { APPEARANCE } from 'app/styles/variables/appearance';
import { FONT_SIZE } from 'app/styles/variables/typography';
import COLORS from 'app/styles/variables/colors';

const AVATAR_BADGE_SIZE = '20px';

export const SScheduleMember = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  gap: 20px;
  border-radius: ${APPEARANCE.BORDER_RADIUS.REGULAR};
  padding-block: ${APPEARANCE.MARGINS.EXTRA_SMALL};

  ${({ theme }) =>
    !theme.hasHours &&
    css`
      padding-top: 0;
    `}
`;

export const SMemberAvatar = styled.div<{ isMe?: boolean }>`
  position: relative;

  &:after {
    display: ${({ isMe }) => (!!isMe ? 'block' : 'none')};
    position: absolute;
    right: 0;
    bottom: 0;
    content: '';
    width: ${AVATAR_BADGE_SIZE};
    height: ${AVATAR_BADGE_SIZE};
    background: ${COLORS.PALETTE.PRIMARY_GOLD};
    border-radius: 50%;
    margin: 5px -5px;
  }
`;

export const SMemberDetails = styled.div`
  display: flex;
  flex-direction: column;

  h3 {
    font-family: unset;
    font-size: ${FONT_SIZE.SMALL};
  }
`;
