import { IScheduleSelectedItem } from './types';

export interface IScheduleListState {
  selectedItem?: IScheduleSelectedItem;
}

export const initialState: IScheduleListState = {};

export enum EScheduleListAction {
  setSelectedItem,
}

export type TScheduleListAction = {
  type: EScheduleListAction.setSelectedItem;
  selectedItem: IScheduleSelectedItem;
};

const scheduleListReducer = (
  state: IScheduleListState,
  action: TScheduleListAction,
): IScheduleListState => {
  switch (action.type) {
    case EScheduleListAction.setSelectedItem: {
      const { selectedItem } = action;
      return {
        ...state,
        selectedItem,
      };
    }
    default:
      return { ...state };
  }
};

export default scheduleListReducer;
