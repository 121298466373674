import { navigation as navigationText } from 'app/constants/translations.json';
import {
  IApplicationNavigation,
  TApplicationNavigation,
} from 'core/models/application-navigation';

interface IMainMenu {
  dashboard: IApplicationNavigation;
  schedule: IApplicationNavigation;
  addressBook: IApplicationNavigation;
  // daysOff: ApplicationNavigation;
}

const mainMenu: TApplicationNavigation<IMainMenu> = {
  dashboard: {
    icon: 'dashboard',
    path: '/',
    text: navigationText.dashboardText,
  },
  schedule: {
    icon: 'schedule',
    path: '/schedule',
    text: navigationText.scheduleText,
  },
  addressBook: {
    icon: 'addressBook',
    path: '/address-book',
    text: navigationText.addressBookText,
  },
  // daysOff: {
  //   path: '/days-off',
  //   text: navigationText.daysOffText,
  // },
};

export default mainMenu;
