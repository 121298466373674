import { ISchedule } from 'app/models/schedule.model';
import { createContext } from 'react';
import { IContainerContext } from 'core/types/container';
import { IActivity } from '../../models/activity.model';

interface IScheduleViewQueries {
  getCurrentSchedule: () => Promise<{ schedule: ISchedule[]; dates: Date[] }>;
  addActivity: (activity: IActivity) => Promise<void>;
}

export const ScheduleViewContainerContext = createContext(
  {} as IContainerContext<IScheduleViewQueries>,
);
