export const APPEARANCE = {
  MARGINS: {
    EXTRA_SMALL: '10px',
    SMALL: '20px',
    REGULAR: '30px',
    LARGE: '60px',
  },

  BORDER_RADIUS: {
    REGULAR: '12px',
    MEDIUM: '18px',
    LARGE: '30px',
  },

  SHADOWS: {
    DEFAULT: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
  },
};
