import { ReactElement, useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
  SScheduleDatesList,
  SScheduleDatesListItem,
  SScheduleDatesListItemLink,
  SScheduleDatesListItemLinkRipples,
} from './schedule-dates.styles';

interface IProps {
  dates: Date[];
}

const ScheduleDates = ({ dates }: IProps): ReactElement => {
  const location = useLocation();

  useEffect(() => {
    if (!location.hash) {
      const [date] = dates;
      if (!date) {
        return;
      }

      window.location.hash = date.getTime().toString();
    }
  }, [dates, location]);

  const isActive = useCallback(
    (date: Date): boolean => {
      const hash = location.hash.slice(1);

      return hash === date.getTime().toString();
    },
    [location.hash],
  );

  return (
    <>
      <SScheduleDatesList>
        {dates.map((date) => (
          <SScheduleDatesListItem key={date.getTime()}>
            <SScheduleDatesListItemLink
              isActive={isActive(date)}
              href={'#' + date.getTime()}
            >
              <SScheduleDatesListItemLinkRipples during={1500}>
                <strong>{date.getDate()}</strong>
                <small>
                  {date.toLocaleString('default', { month: 'short' })}
                </small>
              </SScheduleDatesListItemLinkRipples>
            </SScheduleDatesListItemLink>
          </SScheduleDatesListItem>
        ))}
      </SScheduleDatesList>
    </>
  );
};

export default ScheduleDates;
