import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import appConfig from 'app/config/config';

export const useIsUserAuthorized = (): boolean => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [isUserAuthorized, setIsUserAuthorized] = useState<boolean>(false);
  const {
    auth: { userTokenKey },
  } = appConfig;

  useEffect(() => {
    const isLoggedIn = !!localStorage.getItem(userTokenKey);

    if (!isLoggedIn) {
      navigate('/login');

      return;
    }

    setIsUserAuthorized(isLoggedIn);
  }, [isUserAuthorized, pathname]);

  return isUserAuthorized;
};
