import styled from 'styled-components';
import { LAYOUTS } from 'app/styles/variables/layout';

export const SDashboardGridWrapper = styled.div`
  display: grid;
  grid-template-rows: repeat(1, minmax(0, 1fr));
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-auto-rows: minmax(0, 1fr);
  place-items: stretch;
  gap: ${LAYOUTS.DASHBOARD_GAP};
`;
