import styled from 'styled-components';
import { unStyledList } from 'app/styles/helper.styles';
import { LAYOUTS } from 'app/styles/variables/layout';
import COLORS from 'app/styles/variables/colors';
import { APPEARANCE } from 'app/styles/variables/appearance';

export const SAddressBookListWrapper = styled.ul`
  ${unStyledList};
  padding-inline: ${APPEARANCE.MARGINS.SMALL};

  &:after {
    content: '';
    position: fixed;
    pointer-events: none;
    inset: auto 0 ${LAYOUTS.FOOTER_HEIGHT} 0;
    background: linear-gradient(
      transparent 25%,
      ${COLORS.SECONDARY_BACKGROUND}99
    );
    width: 100vw;
    height: 80px;
    z-index: 1;
  }
`;

export const SAddressBookListItemWrapper = styled.li`
  ${unStyledList};
  background-color: ${COLORS.PRIMARY_BACKGROUND};
  border-radius: ${APPEARANCE.BORDER_RADIUS.REGULAR};
  margin-bottom: ${APPEARANCE.MARGINS.EXTRA_SMALL};
`;
