import axios, { AxiosInstance, AxiosResponse } from 'axios';
import appConfig from 'app/config/config';
import { toast } from 'react-toastify';
import { ECoreErrorMessages } from '../constats/core-messages';
import { IResponseError } from '../models/response-error';
import { EApplicationErrorMessages } from 'app/constants/application-messages';

type HttpMethod = 'get' | 'post' | 'put' | 'delete' | 'patch';

class HttpClient {
  private readonly client: AxiosInstance;

  private readonly TOAST_ERROR_TOKEN = 'httpClientError';

  constructor() {
    this.client = axios.create({
      baseURL: appConfig.api.baseUrl,
    });

    this.handleResponseInterceptor();
  }

  private errorMessage(message: string): void {
    toast.error(message, {
      toastId: this.TOAST_ERROR_TOKEN,
    });
  }

  private setAuthorizationHeader(): void {
    const authToken = localStorage.getItem(appConfig.auth.userTokenKey);
    if (!this.client) {
      return;
    }

    if (!authToken) {
      delete this.client.defaults.headers.common.Authorization;

      return;
    }

    this.client.defaults.headers.common.Authorization = `Bearer ${authToken}`;
  }

  private requestConfiguration(): void {
    this.setAuthorizationHeader();
  }

  private requestError(request: any): void {
    const { status, data } = (request?.response || {}) as IResponseError;

    if (!navigator.onLine) {
      this.errorMessage(ECoreErrorMessages.clientIsOffline);

      return;
    }

    if (!request?.response) {
      this.errorMessage(ECoreErrorMessages.apiError);
    }

    if (500 === status) {
      this.errorMessage(ECoreErrorMessages.internalServerError);
    }

    if (401 === status) {
      localStorage.removeItem(appConfig.auth.userTokenKey);
      this.errorMessage(ECoreErrorMessages.userUnauthorized);
    }

    if (data) {
      /**
       * Custom errors from backend
       */
      const { error, messages } = data;

      this.errorMessage(
        ECoreErrorMessages[error] || EApplicationErrorMessages[error],
        // messages?.error ||
        // error,
      );
    }
  }

  handleResponseInterceptor(): void {
    this.client.interceptors.response.use(
      (response) => response,
      (responseError) => {
        this.requestError(responseError);

        return Promise.reject(responseError);
      },
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  makeRequest<T, R>(
    httpMethod: HttpMethod,
    url: string,
    data?: T,
  ): Promise<AxiosResponse<R>> {
    this.requestConfiguration();

    switch (httpMethod) {
      case 'post':
        return this.client.post<R>(url, data);
      case 'get':
      default:
        return this.client.get<R>(url);
    }
  }

  // TODO: Use endpointName instead of url
  get<R>(url: string): Promise<AxiosResponse<R>> {
    return this.makeRequest<undefined, R>('get', url);
  }

  post<T, R>(url: string, data: T): Promise<AxiosResponse<R>> {
    return this.makeRequest<T, R>('post', url, data);
  }
}

export default new HttpClient();
