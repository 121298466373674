import { ReactElement, useContext, useEffect } from 'react';
import DropDownOptions from 'core/components/dropdown-options/dropdown-options';
import { ScheduleListContext } from '../schedule-list/schedule-list.context';
import { Button } from '@mui/material';
import { EDropDownOptionsAction } from 'core/components/dropdown-options/dropdown-options.reducer';
import { scheduleView } from 'app/constants/translations.json';
import AppContext from 'app/components/app/app.context';
import { TActivityAction } from 'app/models/activity.model';

interface IProps {
  doAction: (action: TActivityAction) => void;
}

const {
  dropDownActions: {
    absenceActionText,
    presenceActionText,
    substitutionActionText,
  },
} = scheduleView;

const ScheduleDropdownOptions = ({ doAction }: IProps): ReactElement => {
  const {
    state: { loggedUser },
  } = useContext(AppContext);
  const {
    state: { selectedItem },
    dropDownItemOptions: {
      state: { forElement, isOpen },
      dispatch,
    },
  } = useContext(ScheduleListContext);
  const { person } = selectedItem || {};
  const { activity = 'ABSENCE' } = person || {};

  useEffect(() => {
    const onScroll = () => {
      dispatch({ type: EDropDownOptionsAction.close });
    };

    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  const isMe = selectedItem?.person?.userId === loggedUser?.id;

  if (!isMe) {
    return <></>;
  }

  let absenceOrPresence: TActivityAction = 'ABSENCE';
  let absenceOrPresenceText = absenceActionText;
  if (activity === 'ABSENCE') {
    absenceOrPresenceText = presenceActionText;
    absenceOrPresence = 'PRESENCE';
  }

  return (
    <>
      <DropDownOptions isOpen={isOpen} forElement={forElement}>
        <>
          {isMe && (
            <>
              <Button
                variant="dropdown-option"
                onClick={() => doAction(absenceOrPresence)}
              >
                {absenceOrPresenceText}
              </Button>
              <hr />
            </>
          )}
          {!isMe && (
            <>
              <Button
                variant="dropdown-option"
                onClick={() => doAction('JOIN')}
              >
                {presenceActionText}
              </Button>
              <hr />
              <Button
                variant="dropdown-option"
                onClick={() => doAction('SUBSTITUTION')}
              >
                {substitutionActionText}
              </Button>
            </>
          )}
        </>
      </DropDownOptions>
    </>
  );
};

export default ScheduleDropdownOptions;
