import { default as httpClient } from 'core/helpers/http-client';
import { getEndpoint } from 'app/config/api';
import { AuthLoginType, IAuth } from 'app/models/auth.model';
import { TLoginForm } from 'app/views/login-view/components/types';

export const loginByTokenApi = (token: string): Promise<IAuth> => {
  return httpClient
    .get<IAuth>(getEndpoint('getLogin', { token }).path)
    .then((res) => res?.data);
};

export const verifyUserByEmailApi = (email: string): Promise<boolean> => {
  return httpClient
    .post<AuthLoginType, boolean>(getEndpoint('postVerifyEmail').path, {
      email,
    })
    .then((res) => res?.data);
};

export const loginApi = (email: string, pin: string): Promise<IAuth> => {
  return httpClient
    .post<TLoginForm, IAuth>(getEndpoint('postLogin').path, {
      email,
      pin,
    })
    .then((res) => res?.data);
};
