import styled, { css } from 'styled-components';

export const SIconWrapper = styled.div<{ customSize?: string }>`
  ${({ customSize }) =>
    customSize &&
    css`
      i[class*='remixicon'] {
        font-size: ${customSize};
      }
    `}
`;
