import styled, { css } from 'styled-components';
import { APPEARANCE } from 'app/styles/variables/appearance';
import COLORS from 'app/styles/variables/colors';
import { smallText, unStyledList } from 'app/styles/helper.styles';
import { FONT_SIZE } from 'app/styles/variables/typography';
import { TActivityAction } from 'app/models/activity.model';

const ACTIVITY_BADGE_SIZE = '28px';

const activityColors: Record<TActivityAction, string> = {
  ABSENCE: COLORS.PALETTE.PRIMARY_RED,
  PRESENCE: COLORS.PALETTE.PRIMARY_GREEN,
  JOIN: '',
  SUBSTITUTION: '',
};

const getActivityColor = (activity: TActivityAction) =>
  activityColors[activity] || COLORS.PALETTE.PRIMARY_GREEN;

export const SScheduleActivity = styled.div`
  cursor: pointer;
  display: flex;
  align-items: stretch;
  align-content: center;
  gap: 25px;
  padding-inline: ${APPEARANCE.MARGINS.SMALL} ${APPEARANCE.MARGINS.SMALL};

  &:first-of-type {
    background: ${COLORS.PALETTE.LIGHT_GOLD};
    border-radius: ${APPEARANCE.BORDER_RADIUS.REGULAR};
    margin-inline: ${APPEARANCE.MARGINS.SMALL};
    padding-inline: 0;

    time {
      strong {
        background: transparent;
      }
    }
  }

  ${({ theme }) =>
    !theme.hasHours &&
    css`
      &:not(:first-of-type, :last-of-type) {
        border-bottom: 1px dashed ${COLORS.PALETTE.PRIMARY_GOLD}55;
      }
    `}
`;

export const SActivityHour = styled.time`
  display: flex;
  flex-basis: 70px;
  align-items: center;
  justify-content: center;
  border-right: 1px dashed ${COLORS.PALETTE.PRIMARY_GOLD}55;
  ${smallText};
  font-size: ${FONT_SIZE.EXTRA_SMALL};

  strong {
    background: ${COLORS.PALETTE.LIGHT_GOLD};
    padding: 5px 6px;
    border-radius: 5px;
  }
`;

export const SActivityMembersWrapper = styled.div`
  flex: 1;
`;

export const SActivityMembers = styled.ul`
  ${unStyledList};
`;

export const SActivityBadge = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${APPEARANCE.MARGINS.SMALL};

  &:before {
    content: '';
    display: block;
    width: ${ACTIVITY_BADGE_SIZE};
    height: ${ACTIVITY_BADGE_SIZE};
    border: 8px solid ${COLORS.PALETTE.PRIMARY_GREEN};
    border-color: ${({ theme }) => getActivityColor(theme.activity)};
    border-radius: 50%;
  }
`;
