import { ReactElement, useContext, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { SAuthLayoutView, SAuthLayoutWrapper } from './auth-layout.styles';
import useChangeMetaThemeColor from 'core/hooks/use-change-meta-theme-color';
import COLORS from 'app/styles/variables/colors';
import AppContext from 'app/components/app/app.context';
import { EAppAction } from 'app/components/app/app.reducer';

const UNLOCK_SCREEN_AFTER_TIME = 25000;

const AuthLayout = (): ReactElement => {
  const {
    state: { isLoading },
    dispatch,
  } = useContext(AppContext);

  useChangeMetaThemeColor(COLORS.PRIMARY_BACKGROUND);

  useEffect(() => {
    if (!isLoading) {
      return;
    }

    const unlockScreen = setTimeout(() => {
      dispatch({ type: EAppAction.hideLoader });
    }, UNLOCK_SCREEN_AFTER_TIME);

    return () => {
      clearTimeout(unlockScreen);
    };
  }, [isLoading]);

  return (
    <SAuthLayoutWrapper>
      <SAuthLayoutView isLoading={isLoading}>
        <Outlet />
      </SAuthLayoutView>
    </SAuthLayoutWrapper>
  );
};

export default AuthLayout;
