import { FC, ReactElement, useContext } from 'react';
import AppContext from 'app/components/app/app.context';
import { EAppAction } from 'app/components/app/app.reducer';
import { getCurrentScheduleApi } from 'app/fetch/api/schedule.api';
import { ScheduleViewContainerContext } from './schedule-view.context';
import { postActivityAddApi } from 'app/fetch/api/activities.api';
import { IActivity } from 'app/models/activity.model';
import { isEmpty } from 'lodash';

const ScheduleViewContainer: FC = ({ children }): ReactElement => {
  const { dispatch: appDispatch } = useContext(AppContext);

  const getCurrentSchedule = async () => {
    appDispatch({ type: EAppAction.showLoader });

    return getCurrentScheduleApi()
      .then((schedule) => ({
        schedule,
        dates: schedule.map((item) => new Date(item.date)),
      }))
      .finally(() => appDispatch({ type: EAppAction.hideLoader }));
  };

  const addActivity = async (activity: IActivity): Promise<void> => {
    if (isEmpty(activity)) {
      return;
    }

    await postActivityAddApi(activity);
  };

  return (
    <ScheduleViewContainerContext.Provider
      value={{
        queries: {
          getCurrentSchedule,
          addActivity,
        },
      }}
    >
      {children}
    </ScheduleViewContainerContext.Provider>
  );
};

export default ScheduleViewContainer;
