import { ReactElement } from 'react';
import Container from '../container/container';
import { SInfoPageWrapper, SInfoPageMain } from './info-page.styles';

interface IProps {
  header: string;
  text: string;
}

const InfoPage = ({ header, text }: IProps): ReactElement => {
  return (
    <>
      <SInfoPageWrapper>
        <Container>
          <SInfoPageMain>
            <h1>{header}</h1>
            <p>{text}</p>
          </SInfoPageMain>
        </Container>
      </SInfoPageWrapper>
    </>
  );
};

export default InfoPage;
