import { FC, ReactElement } from 'react';
import { useIsUserAuthorized } from 'core/hooks/use-is-user-authorized';

const PrivateRoute: FC = ({ children }): ReactElement => {
  const isUserAuthorized = useIsUserAuthorized();

  if (!isUserAuthorized) {
    return <></>;
  }

  return <>{children}</>;
};

export default PrivateRoute;
