import { createGlobalStyle } from 'styled-components';
import COLORS from './variables/colors';
import { FONT_SERIF, FONT_SIZE, FONT_WEIGHT } from './variables/typography';
import 'remixicon/fonts/remixicon.css';
import 'react-toastify/dist/ReactToastify.css';
import { smallText } from './helper.styles';
import './fonts.css';

const GlobalStyles = createGlobalStyle`
  html {
    font-size: 62.5%;
    //scroll-behavior: smooth;
  }

  body {
    //overflow: hidden;
    height: 100vh;
    background: ${COLORS.SECONDARY_BACKGROUND};
    font-size: ${FONT_SIZE.REGULAR};
    font-weight: ${FONT_WEIGHT.REGULAR};
    color: ${COLORS.PRIMARY_TEXT};
    padding: 0;
    margin: 0;
  }

  *,
  :before,
  :after {
    box-sizing: border-box;
  }
  
  a {
    color: inherit;
    text-decoration: none;
  }
  
  small,
  time {
    ${smallText};
  }
  
  strong {
    font-size: ${FONT_WEIGHT.BOLD};
  }
  
  h1, h2, h3, h4, h5, h6 {
    font-family: ${FONT_SERIF};
    font-size: ${FONT_SIZE.REGULAR};
    padding: 0;
    margin: 0;
  }
  
  h1 {
    font-size: ${FONT_SIZE.LARGE};
  }
  h2 {
    font-size: ${FONT_SIZE.MEDIUM};
  }
  h5 {
    font-size: ${FONT_SIZE.EXTRA_SMALL};
  }
  
  ins[id] {
    display: block;
  }
  
  p {
    line-height: 1.5;
  }
`;

export default GlobalStyles;
