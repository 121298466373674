import { ReactElement, useContext, useEffect, useState } from 'react';
import TopBar from 'app/components/top-bar/top-bar';
import { ISchedule } from 'app/models/schedule.model';
import ScheduleList from './components/schedule/components/schedule-list/schedule-list';
import ScheduleDates from './components/schedule/components/schedule-dates/schedule-dates';
import COLORS from 'app/styles/variables/colors';
import useChangeMetaThemeColor from 'core/hooks/use-change-meta-theme-color';
import AppContext from 'app/components/app/app.context';
import { SScheduleViewMain } from './schedule-view.styles';
import { ScheduleViewContainerContext } from './schedule-view.context';
import { IScheduleSelectedItem } from './components/schedule/components/schedule-list/types';
import { TActivityAction } from '../../models/activity.model';

const ScheduleView = (): ReactElement => {
  useChangeMetaThemeColor(COLORS.PRIMARY_BACKGROUND);

  const {
    state: { isLoading },
  } = useContext(AppContext);

  const {
    queries: { getCurrentSchedule, addActivity },
  } = useContext(ScheduleViewContainerContext);

  const [currentSchedule, setCurrentSchedule] = useState<ISchedule[]>([]);
  const [scheduleDates, setScheduleDates] = useState<Date[]>([]);

  const refetchSchedule = () => {
    getCurrentSchedule().then(({ schedule, dates }) => {
      setCurrentSchedule(schedule);
      setScheduleDates(dates);
    });
  };

  useEffect(() => {
    refetchSchedule();
  }, []);

  const onAddActivity = (
    item: IScheduleSelectedItem,
    action: TActivityAction,
  ) => {
    const { date, hour, person } = item;
    const { id: personId } = person || {};

    addActivity({
      personId,
      date,
      hour,
      type: action,
    }).then(() => refetchSchedule());
  };

  return (
    <>
      {!isLoading && (
        <TopBar>
          <ScheduleDates dates={scheduleDates} />
        </TopBar>
      )}
      <SScheduleViewMain>
        <ScheduleList
          schedule={currentSchedule}
          onItemDoAction={onAddActivity}
        />
      </SScheduleViewMain>
    </>
  );
};

export default ScheduleView;
