import { MouseEvent, ReactElement, useContext } from 'react';
import { ThemeProvider } from 'styled-components';
import { ScheduleListContext } from '../../schedule-list.context';
import { EDropDownOptionsAction } from 'core/components/dropdown-options/dropdown-options.reducer';
import {
  SActivityBadge,
  SScheduleActivity,
  SActivityMembersWrapper,
  SActivityMembers,
  SActivityHour,
} from './schedule-activity.styles';
import ScheduleMember from '../schedule-member/schedule-member';
import { IScheduleMassPerson } from 'app/models/schedule.model';

interface IProps {
  hour?: string;
  persons: IScheduleMassPerson[];

  onClick: (person: IScheduleMassPerson) => void;
}

const ScheduleActivity = ({ hour, persons, onClick }: IProps): ReactElement => {
  const {
    dropDownItemOptions: { dispatch },
  } = useContext(ScheduleListContext);

  const [person] = persons || [];

  const onShowDropDown = (event: MouseEvent<HTMLElement>) => {
    const { currentTarget } = event || {};
    if (!currentTarget) {
      return;
    }

    dispatch({
      type: EDropDownOptionsAction.forElement,
      forElement: currentTarget,
    });
    dispatch({ type: EDropDownOptionsAction.open });
  };

  return (
    <ThemeProvider theme={{ hasHours: !!hour, activity: person.activity }}>
      <SScheduleActivity onClick={onShowDropDown}>
        <SActivityHour>{hour && <strong>{hour}</strong>}</SActivityHour>
        <SActivityMembersWrapper>
          <SActivityMembers>
            {/*{persons?.map((person, index) => (*/}
            <div role="button" onClick={() => onClick(person)}>
              <ScheduleMember {...person} />
            </div>
            {/*))}*/}
          </SActivityMembers>
        </SActivityMembersWrapper>
        <SActivityBadge />
      </SScheduleActivity>
    </ThemeProvider>
  );
};

export default ScheduleActivity;
