import styled from 'styled-components';
import { unStyledList } from 'app/styles/helper.styles';
import { APPEARANCE } from 'app/styles/variables/appearance';

export const SScheduleListWrapper = styled.ul`
  ${unStyledList};
`;

export const SScheduleItemWrapper = styled.li`
  &:not(:last-of-type) {
    margin-block: ${APPEARANCE.MARGINS.REGULAR};
  }
`;
