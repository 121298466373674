import styled, { css } from 'styled-components';
import { unStyledList } from 'app/styles/helper.styles';
import COLORS from 'app/styles/variables/colors';
import {
  FONT_SERIF,
  FONT_SIZE,
  FONT_WEIGHT,
} from 'app/styles/variables/typography';
import Ripples from 'react-ripples';

export const SScheduleDatesList = styled.ul`
  ${unStyledList};
  display: flex;
  gap: 10px;
`;

export const SScheduleDatesListItem = styled.li`
  flex: 1;
`;

export const SScheduleDatesListItemLink = styled.a<{
  isActive?: boolean;
}>`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 72px;
  background: ${COLORS.SECONDARY_BACKGROUND};
  border-radius: 8px;

  strong {
    font-size: ${FONT_SIZE.MEDIUM};
  }

  small {
    display: block;
    font-size: ${FONT_SIZE.SMALL};
    font-family: ${FONT_SERIF};
    font-weight: ${FONT_WEIGHT.REGULAR};
    text-transform: capitalize;
  }

  ${({ isActive }) =>
    isActive &&
    css`
      background-color: ${COLORS.PRIMARY_ACTIVE};
      color: ${COLORS.LIGHT_TEXT};

      small {
        color: ${COLORS.LIGHT_TEXT};
      }
    `};
`;

export const SScheduleDatesListItemLinkRipples = styled(Ripples)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;
