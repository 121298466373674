import { ReactElement, useContext } from 'react';
import AppContext from 'app/components/app/app.context';
import Avatar from 'app/components/avatar/avatar';
import {
  SMemberAvatar,
  SScheduleMember,
  SMemberDetails,
} from './schedule-member.styles';
import { IScheduleMassPerson } from 'app/models/schedule.model';

const ScheduleMember = (person: IScheduleMassPerson): ReactElement => {
  const {
    state: { loggedUser },
  } = useContext(AppContext);
  const { id, photo, firstname } = person;

  const isMe = id === loggedUser?.person?.id;

  return (
    <li key={id}>
      <SScheduleMember>
        <SMemberAvatar isMe={isMe}>
          <Avatar image={photo} />
        </SMemberAvatar>
        <SMemberDetails>
          <h3>{firstname}</h3>
          {/*<small>{person.lastname}</small>*/}
        </SMemberDetails>
      </SScheduleMember>
    </li>
  );
};

export default ScheduleMember;
