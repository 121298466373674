import { useEffect, useState } from 'react';
import appConfig from 'app/config/config';

const useIsUserLoggedIn = (): boolean => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const {
    auth: { userTokenKey },
  } = appConfig;

  useEffect(() => {
    setIsLoggedIn(!!localStorage.getItem(userTokenKey));
  }, [userTokenKey]);

  return isLoggedIn;
};

export default useIsUserLoggedIn;
