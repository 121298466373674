type TAppEnv = 'dev' | 'prod';

const checkEnvConfigPrefix = (
  configKey: string,
  configEnvPrefix = 'REACT_APP_',
): string => {
  if (!configKey.startsWith(configEnvPrefix)) {
    return configEnvPrefix + configKey;
  }

  return configKey;
};

export const getEnvConfigValue = (
  configKey: 'API_BASE_URL' | 'ENV',
): string | TAppEnv => {
  const configEnvKey = checkEnvConfigPrefix(configKey);
  const value = process.env[configEnvKey];

  if (!value) {
    console.warn(`There is no value for \`${configEnvKey}\` in your .env file`);

    return '';
  }

  return value;
};
