import styled from 'styled-components';
import { APPEARANCE } from 'app/styles/variables/appearance';

export const SScheduleViewMain = styled.div`
  padding-block: ${APPEARANCE.MARGINS.SMALL};

  ins[id] {
    scroll-margin-top: 130px;
  }
`;
