import { IUser } from 'app/models/user.model';

export interface IAppState {
  loggedUser: IUser;
  isLoading: boolean;
}

export const initialState: IAppState = {
  loggedUser: {} as IUser,
  isLoading: false,
};

export enum EAppAction {
  setLoggedUser,
  showLoader,
  hideLoader,
}

export type TAppAction =
  | {
      type: EAppAction.setLoggedUser;
      user: IUser;
    }
  | {
      type: EAppAction.showLoader;
    }
  | {
      type: EAppAction.hideLoader;
    };

const appLoaderReducer = (state: IAppState, action: TAppAction): IAppState => {
  switch (action.type) {
    case EAppAction.setLoggedUser:
      const { user: loggedUser } = action;
      return {
        ...state,
        loggedUser,
      };
    case EAppAction.showLoader:
      return {
        ...state,
        isLoading: true,
      };
    case EAppAction.hideLoader:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return { ...state };
  }
};

export default appLoaderReducer;
