const icons = {
  schedule: 'calendar',
  addressBook: 'map-pin-5',
  members: 'folder',
  add: 'add',
  remove: '',
  edit: '',
  communion: 'add-circle',
  floor: 'footprint',
  dashboard: 'home',
  move: 'menu',
};

export type TIcon = keyof typeof icons;

export type TIconSize =
  | 'lg'
  | 'xl'
  | 'xxs'
  | 'xs'
  | 'sm'
  | '1x'
  | '2x'
  | '3x'
  | '4x'
  | '5x'
  | '6x'
  | '7x'
  | '8x'
  | '9x'
  | '10x'
  | 'fw';

export default icons;
