const COLORS = {
  PRIMARY_BACKGROUND: '#FFFFFF',
  SECONDARY_BACKGROUND: '#F3F3F3',

  PRIMARY_TEXT: '#313131',
  SECONDARY_TEXT: '#999999',
  LIGHT_TEXT: '#FFFFFF',

  PRIMARY_ACTIVE: '#BE9F56',
  LIGHT_ACTIVE: 'rgba(190,159,86,0.35)',

  LINE: '#DBD7D7',

  PALETTE: {
    PRIMARY_GOLD: '#BE9F56',
    LIGHT_GOLD: 'rgba(190,159,86,0.11)',
    LIGHT_GRAY: '#CCCCCC',
    PRIMARY_GREEN: '#468966',
    PRIMARY_RED: '#B64926',
  },
};

export default COLORS;
