import { ReactElement, useContext } from 'react';
import Menu from './components/menu/menu';
import AppContext from 'app/components/app/app.context';
import {
  SMainLayoutMenu,
  SMainLayoutWrapper,
  SMainLayoutView,
} from './main-layout.styles';
import { Outlet } from 'react-router-dom';

const MainLayout = (): ReactElement => {
  const {
    state: { isLoading },
  } = useContext(AppContext);

  return (
    <SMainLayoutWrapper>
      <SMainLayoutMenu>
        <Menu />
      </SMainLayoutMenu>
      <SMainLayoutView isLoaded={!isLoading}>
        <Outlet />
      </SMainLayoutView>
    </SMainLayoutWrapper>
  );
};

export default MainLayout;
