import styled from 'styled-components';
import COLORS from 'app/styles/variables/colors';

export const SSpinnerWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;
`;

export const SSpinner = styled.div`
  width: 60px;
  aspect-ratio: 1;

  position: relative;
  margin: 100px auto;

  &:before,
  &:after {
    display: block;
    content: '';
    width: 100%;
    aspect-ratio: 1;
    border-radius: 50%;
    background-color: ${COLORS.PRIMARY_ACTIVE};
    opacity: 0.3;
    position: absolute;
    top: 0;
    left: 0;

    -webkit-animation: spinnerBouncing 2s infinite ease-in-out;
    animation: spinnerBouncing 2s infinite ease-in-out;
  }

  &:after {
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
  }

  @keyframes spinnerBouncing {
    0%,
    100% {
      transform: scale(0);
      -webkit-transform: scale(0);
    }
    50% {
      transform: scale(1);
      -webkit-transform: scale(1);
    }
  }
`;
