import { FormEvent, useState } from 'react';

interface IForm<T> {
  data: T;
  isValid: boolean;

  onSubmit: (e: FormEvent<HTMLFormElement>) => void;
  onChange: (e: FormEvent<HTMLFormElement>) => void;
}

export const useForm = <T>(): IForm<T> => {
  const [data, setData] = useState<T>({} as T);
  const [isValid, setIsValid] = useState(false);

  const onChange = (e: FormEvent<HTMLFormElement>) => {
    const form = e.currentTarget;
    const formData = new FormData(form as HTMLFormElement);
    formData.forEach((value, key) => setData({ ...data, [key]: value }));

    setIsValid(form.checkValidity());
  };

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  };

  return {
    data,
    isValid,
    onChange,
    onSubmit,
  };
};
