import ScheduleView from 'app/views/schedule-view/schedule-view';
import AddressBookView from 'app/views/address-book-view/address-book-view';
import { TApplicationRoutes } from 'core/models/application-routes';
import MainLayout from 'app/layouts/main-layout/main-layout';
import DashboardView from 'app/views/dashboard-view/dashboard-view';
import DashboardViewContainer from 'app/views/dashboard-view/dashboard-view.container';
import ScheduleViewContainer from '../views/schedule-view/schedule-view.container';

const applicationRoutes: TApplicationRoutes = [
  {
    path: '/',
    view: DashboardView,
    container: DashboardViewContainer,
    layout: MainLayout,
  },
  {
    path: '/schedule',
    view: ScheduleView,
    container: ScheduleViewContainer,
    layout: MainLayout,
  },
  {
    path: '/address-book',
    view: AddressBookView,
    layout: MainLayout,
  },
  // {
  //   path: '/days-off',
  //   view: DaysOffView,
  // },
];

export default applicationRoutes;
