import { memo, ReactElement, useContext, useEffect, useState } from 'react';
import DashboardGrid from './components/dashboard-grid/dashboard-grid';
import AppVersion from 'app/components/app/app-version';
import DashboardTile from './components/dashboard-grid/components/dashboard-tile/dashboard-tile';
import mainMenu from 'app/config/navigation';
import Avatar from 'app/components/avatar/avatar';
import { dashboardView } from 'app/constants/translations.json';
import Header from 'app/components/header/header';
import { IDashboard } from 'app/models/dashboard.model';
import { FormattedDate } from 'react-intl';
import {
  SDashboardViewWrapper,
  SDashboardViewTilesWrapper,
  SDashboardTileCalendar,
  SDashboardViewAppVersionWrapper,
} from './dashboard-view.styles';
import { DashboardViewContainerContext } from './dashboard-view.context';
import { isEmpty } from 'lodash';

const {
  headerText,
  calendarCaptionText,
  calendarDaysText,
  calendarTodayText,
  counterCaptionText,
  memberCaptionText,
} = dashboardView;

const DashboardView = (): ReactElement => {
  const [dashboard, setDashboard] = useState<IDashboard>({} as IDashboard);
  const { communionCounter, guy, nextMass } = dashboard;
  const { inDays: massInDays, date: massDate } = nextMass || {};
  const { photo: avatarImage } = guy || {};

  const {
    queries: { getDashboard },
  } = useContext(DashboardViewContainerContext);

  useEffect(() => {
    getDashboard().then((dashboardFromContainer) =>
      setDashboard(dashboardFromContainer),
    );
  }, []);

  return (
    <>
      <Header text={headerText} />
      <SDashboardViewWrapper>
        <SDashboardViewTilesWrapper>
          <DashboardGrid>
            <DashboardTile
              caption={calendarCaptionText}
              cols="1 / 4"
              link={mainMenu.schedule.path}
              icon="schedule"
            >
              <SDashboardTileCalendar>
                {!isEmpty(nextMass) && (
                  <>
                    <span>
                      {!massInDays
                        ? calendarTodayText
                        : `${massInDays} ${calendarDaysText}`}
                    </span>
                    <time>
                      <FormattedDate
                        value={massDate}
                        weekday="short"
                        day="2-digit"
                        month="short"
                      />{' '}
                    </time>
                  </>
                )}
              </SDashboardTileCalendar>
            </DashboardTile>
            <DashboardTile
              link={mainMenu.schedule.path}
              caption={memberCaptionText}
            >
              <Avatar image={avatarImage} size="medium" />
            </DashboardTile>
            <DashboardTile
              caption={counterCaptionText}
              link={mainMenu.addressBook.path}
              icon="communion"
              value={communionCounter}
            />
          </DashboardGrid>
        </SDashboardViewTilesWrapper>
        <SDashboardViewAppVersionWrapper>
          <AppVersion />
        </SDashboardViewAppVersionWrapper>
      </SDashboardViewWrapper>
    </>
  );
};

export default memo(DashboardView);
