import styled, { css } from 'styled-components';
import Ripples from 'react-ripples';
import COLORS from 'app/styles/variables/colors';
import { LAYOUTS } from 'app/styles/variables/layout';
import { FONT_SIZE, FONT_WEIGHT } from 'app/styles/variables/typography';
import { APPEARANCE } from 'app/styles/variables/appearance';

export const SDashboardTileRipple = styled(Ripples)<{
  cols?: string;
  rows?: string;
}>`
  cursor: pointer;
  aspect-ratio: 1;
  background: ${COLORS.PRIMARY_BACKGROUND};
  border-radius: ${APPEARANCE.BORDER_RADIUS.LARGE};
  padding: ${APPEARANCE.MARGINS.SMALL};

  ${({ cols }) =>
    !!cols &&
    css`
      grid-column: ${cols};
      aspect-ratio: auto;
      margin-right: ${LAYOUTS.DASHBOARD_GAP};
    `};

  ${({ rows }) =>
    !!rows &&
    css`
      grid-row: ${rows};
      aspect-ratio: auto;
    `};
`;

export const SDashboardTileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  height: 100%;
`;

export const SDashboardTileCaption = styled.h4`
  width: min-content;
  font-weight: ${FONT_WEIGHT.MEDIUM};
  z-index: 1;
  padding: 5px;
`;

export const SDashboardTileContent = styled.div`
  flex: 1;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  white-space: nowrap;
`;

export const SDashboardTileValue = styled.span`
  font-size: ${FONT_SIZE.EXTRA_LARGE};
  font-weight: ${FONT_WEIGHT.THIN};
  line-height: 1;
`;
