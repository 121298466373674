import { ReactElement, useContext, useState } from 'react';
import { authView } from 'app/constants/translations.json';
import EmailForm from './components/email-form/email-form';
import {
  SLoginFormWrapper,
  SLoginMessage,
  SLoginMessageWrapper,
  SLoginView,
} from './login-view.styles';
import { TLoginForm } from './components/types';
import PinForm from './components/pin-form/pin-form';
import { toast } from 'react-toastify';
import { LoginViewContainerContext } from './login-view.context';

const {
  headerText,
  loginMessage,
  resentPinMessage,
  loginForm: { emailVerifiedMessage },
} = authView;

const LoginView = (): ReactElement => {
  const [verifyEmail, setVerifyEmail] = useState('');
  const [isEmailVerified, setIsEmailVerified] = useState(false);

  const {
    queries: { verifyUserByEmail, loginUser },
  } = useContext(LoginViewContainerContext);

  const onEmailFormSubmit = (data: TLoginForm) => {
    const { email } = data;
    if (!email) {
      return;
    }

    setVerifyEmail(email);
    verifyUserByEmail(email).then((isVerified) =>
      setIsEmailVerified(isVerified),
    );
  };

  const onResendPinForEmail = () => {
    toast.success(resentPinMessage);
    onEmailFormSubmit({ email: verifyEmail });
  };

  const onPinFormSubmit = async (data: TLoginForm) => {
    const { pin } = data;
    if (!pin) {
      return;
    }

    loginUser(verifyEmail, pin);
  };

  const showPinForm = isEmailVerified && !!verifyEmail;
  const messageText = showPinForm ? emailVerifiedMessage : loginMessage;

  return (
    <SLoginView>
      <SLoginMessageWrapper>
        <SLoginMessage>
          <h1>{headerText}</h1>
          <p>{messageText}</p>
        </SLoginMessage>
      </SLoginMessageWrapper>
      <SLoginFormWrapper>
        {showPinForm ? (
          <PinForm
            onFormSubmit={onPinFormSubmit}
            onResend={onResendPinForEmail}
          />
        ) : (
          <EmailForm onFormSubmit={onEmailFormSubmit} />
        )}
      </SLoginFormWrapper>
    </SLoginView>
  );
};

export default LoginView;
